import React from "react";
import { Mermaid } from "../../../src/components/Mermaid";

export const CdnPipeline: React.FC = () => {
  return (
    <Mermaid
      chart={`
          graph TD
              Database[(Database)] --> Server
              subgraph " "
              Server -- index.html --> CDN{CDN}
              Server -- image.jpg --> CDN
              Server -- styles.css --> CDN
              Server -- index.js --> CDN
              CDN -- index.html --> Client
              CDN -- image.jpg --> Client
              CDN -- styles.css --> Client
              CDN -- index.js --> Client
              end
    `}
    />
  );
};
