import React from "react";
import { Mermaid } from "../../../src/components/Mermaid";

export const ClientCachePipeline: React.FC = () => {
  return (
    <Mermaid
      chart={`
          graph TD
              Database[(Database)] --> Server
              subgraph " "
              Server -- index.html --> ClientCache{Client Cache}
              Server -- image.jpg --> ClientCache
              Server -- styles.css --> ClientCache
              Server -- index.js --> ClientCache
              ClientCache -- index.html --> Client
              ClientCache -- image.jpg --> Client
              ClientCache -- styles.css --> Client
              ClientCache -- index.js --> Client
              end
    `}
    />
  );
};
