import React from "react";
import { GitGraph } from "../../../src/components/GitGraph";

export const ForgotToCreateABranchSln: React.FC = () => {
  return (
    <GitGraph>
      {(gitgraph) => {
        const origin = gitgraph.branch("origin/master");
        origin.commit("1");
        origin.commit("2");
        origin.commit("3");
        const feature = origin.branch("feature");
        feature.commit("4");
        feature.commit("5");
      }}
    </GitGraph>
  );
};
