import React from "react";
import { Mermaid } from "../../../src/components/Mermaid";

export const EventLoop: React.FC = () => {
  return (
    <Mermaid
      chart={`
        sequenceDiagram
            autonumber
            CallStack->>EventTable: [Timeout] [Callback]
            EventTable->>EventQueue: [Callback]
            Note over EventTable: [Timeout] expires
            loop CallStack.length === 0
                EventQueue->>CallStack: [Callback]
            end
    `}
    />
  );
};
