import React from "react";
import {
  Gitgraph,
  Mode,
  Orientation,
  templateExtend,
  TemplateName,
} from "@gitgraph/react";
import { BaseProps } from "./BaseProps";
import theme from "../gatsby-plugin-theme-ui";
import { css, cx } from "@emotion/css";

export const GitGraph: React.FC<
  BaseProps<React.ComponentProps<typeof Gitgraph>>
> = ({ className, children }) => {
  return (
    <div
      className={cx(
        css({
          margin: 0,
        }),
        className,
      )}
    >
      <Gitgraph
        options={{
          mode: Mode.Compact,
          orientation: Orientation.Horizontal,
          template: templateExtend(TemplateName.Metro, {
            colors: [theme.colors.primary, theme.colors.secondary],
            branch: {
              label: {
                bgColor: theme.colors.background,
              },
              spacing: 100,
            },
            commit: {
              spacing: 100,
            },
          }),
        }}
      >
        {children as any}
      </Gitgraph>
    </div>
  );
};
