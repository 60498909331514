import React, { useEffect } from "react";
import mermaid from "mermaid";
import { css, cx } from "@emotion/css";
import { BaseProps } from "./BaseProps";

export const Mermaid: React.FC<
  BaseProps<{
    chart: string;
  }>
> = ({ className, chart }) => {
  mermaid.initialize({
    startOnLoad: true,
    theme: "dark",
  });

  useEffect(() => {
    mermaid.contentLoaded();
  }, []);

  return (
    <div
      className={cx(
        css({
          display: "flex",
          justifyContent: "center",
        }),
        "mermaid",
        className,
      )}
    >
      {chart}
    </div>
  );
};
