import React from "react";
import { GitGraph } from "../../../src/components/GitGraph";

export const MergeBranch: React.FC = () => {
  return (
    <GitGraph>
      {(gitgraph) => {
        const master = gitgraph.branch("master");
        master.commit("1");
        master.commit("2");

        const feature = master.branch("feature");
        feature.commit("3");

        master.merge(feature);
      }}
    </GitGraph>
  );
};
