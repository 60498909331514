import React from "react";
import { GitGraph } from "../../../src/components/GitGraph";

export const ForgotToCreateABranch: React.FC = () => {
  return (
    <GitGraph>
      {(gitgraph) => {
        const origin = gitgraph.branch("origin/master");
        origin.commit("1");
        origin.commit("2");
        origin.commit("3");
        const master = origin.branch("master");
        master.commit("4");
        master.commit("5");
      }}
    </GitGraph>
  );
};
