import React, { useEffect, useState } from "react";
import Prism from "@theme-ui/prism";
import { BaseProps } from "./BaseProps";
import { cx } from "@emotion/css";

export const GitHubFile: React.FC<
  BaseProps<{
    user: string;
    repo: string;
    branch: string;
    path: string;
    language: string;
    highlightLines?: number[];
  }>
> = ({
  className,
  user,
  repo,
  branch,
  path,
  language,
  highlightLines = [],
}) => {
  const [data, setData] = useState<string>();

  useEffect(() => {
    const get = async () => {
      const res = await fetch(
        `https://raw.githubusercontent.com/${user}/${repo}/${branch}/${path}`,
      );

      if (res.status !== 200) {
        return;
      }

      setData(await res.text());
    };

    void get();
  }, []);

  if (!data) {
    return null;
  }

  // @TODO this will work with an upgrade to @theme-ui/prism
  // https://github.com/system-ui/theme-ui/pull/895
  // const highlighted = data.split("\n").map((line, i) => {
  //   if (highlightLines.some(_ => _ === i)) {
  //     return `${line} // highlight-line`;
  //   } else {
  //     return line;
  //   }
  // }).join("\n");

  return (
    <Prism className={cx(`language-${language}`, className)}>{data}</Prism>
  );
};
