import { InitialPipeline } from "../../../../content/posts/caching-types/InitialPipeline";
import { ServiceCachePipeline } from "../../../../content/posts/caching-types/ServiceCachePipeline";
import { CdnPipeline } from "../../../../content/posts/caching-types/CdnPipeline";
import { ClientCachePipeline } from "../../../../content/posts/caching-types/ClientCachePipeline";
import * as React from 'react';
export default {
  InitialPipeline,
  ServiceCachePipeline,
  CdnPipeline,
  ClientCachePipeline,
  React
};